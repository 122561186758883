import './expand-section';
import './navigation';
import './scroll-to';
import './menu-on-scroll';
import './animations';
import './image-gallery-slider-configuration';
import './_body-view-height';

var ua = window.navigator.userAgent;
var msie = ua.indexOf('MSIE ');
if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  $('body').addClass('ie');
}
