let header = document.getElementById('header-container')

if(header) {
    let openMenu = header.querySelector('.open-menu');
    let menu = header.querySelector('.menu');
    let body = document.querySelector("body");
    let closeButton = header.querySelectorAll('.close-menu, .background-trigger');
    console.log(openMenu);

    
    openMenu.addEventListener('click', function () {
        body.style.overflow = "hidden";
        menu.classList.add("show");
        setTimeout(function() {
            menu.classList.add("animate-show");
        }, 1);
    })

    closeButton.forEach(element => {
        element.addEventListener('click', function () {
            body.style.overflow = "visible";
            menu.classList.remove("animate-show");
            setTimeout(function() {
                menu.classList.remove("show");
            }, 400);
        })
    });
}