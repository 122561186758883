var $expandButton = $('.business-areas-preview .content .business-area .content-top');
$expandButton.click(function(e) {
    var $expandableContent = $(this).parent().children('.text-content');
    if (($(window).width() < 600) && ($(this).parent().children().hasClass('expand'))) {
        $expandableContent.removeClass('expanded');
        $(this).removeClass("expand");
        $(this).parent().removeClass("bg-expand");
    }
    else {
        if ($(window).width() < 600) {
            $expandableContent.addClass('expanded');
            $(this).addClass("expand");
            $(this).parent().addClass("bg-expand");
        }
    }
});