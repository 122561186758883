import bodymovin from 'lottie-web';

var animations = document.querySelectorAll('.animation');
var animation;

function setup() {
    animations.forEach(element => loadAnimation(element));
}

setup();
setTimeout(setup, 1000);
setTimeout(setup, 3000);

function loadAnimation(element) {
    if (element.classList.contains('loaded')) {
        return;
    }

    var data = element.querySelector('.data code');

    animation = bodymovin.loadAnimation({
        container: element, // Required
        animationData: JSON.parse(data.innerHTML),
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
        name: "", // Name for future reference. Optional.
    });

    element.classList.add('loaded');
}
